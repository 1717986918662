// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/bg-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-page[data-v-7589b93f] {\n  background: #fff;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 9.6rem;\n  background-repeat: no-repeat;\n}\n.login-title[data-v-7589b93f] {\n  margin-top: 30%;\n}\n.login-title[data-v-7589b93f],\n.login-title-less[data-v-7589b93f] {\n  text-align: left !important;\n  margin-left: 6%;\n}\n.border-p[data-v-7589b93f] {\n  width: 8%;\n  height: 0.10667rem;\n  background: #1989fa;\n  margin-top: 5% !important;\n  margin-left: 6%;\n}\n.div-input[data-v-7589b93f] {\n  margin-top: 10% !important;\n}\n.div-input[data-v-7589b93f] .van-field__label {\n  border-right: 0.02667rem solid #aaacaf;\n}\n.van-btn[data-v-7589b93f] {\n  margin-top: 30% !important;\n}\n", ""]);
// Exports
module.exports = exports;
