<template>
  <div class="login-page page">
    <p class="login-title">
      <!-- 贵阳综保天际 -->
      河南农投发展
    </p>
    <p class="login-title-less">
      供应链管理系统
    </p>
    <p class="border-p" />
    <van-form class="login-form" @submit="login">
      <div class="div-input">
        <van-field v-model="loginForm.username" placeholder="请输入用户名" :rules="rules.username" label="用户名" />
        <!-- left-icon="manager"  -->
        <van-field v-model="loginForm.password" placeholder="请输入密码" :rules="rules.password" label="密码" type="password" />
        <!-- left-icon="lock" -->
      </div>
      <van-button class="van-btn" block type="info" native-type="submit" :disabled="loading">
        登 录
      </van-button>
    </van-form>
    <!-- <div class="register-button">
      <van-button block type="info" @click="$router.push({path:'/register'})">
        新用户注册
      </van-button>
    </div> -->
    <p class="examine-title">
      {{ constants.examineTitle }}
    </p>
  </div>
</template>

<script>
import { Field, Form, Button, Toast, NoticeBar } from 'vant'
export default {
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar
  },
  data () {
    return {
      loginForm: {
        client_id: 'c1',
        client_secret: 'secret',
        grant_type: 'password',
        username: '',
        password: ''
      },
      rules: {
        username: [{ required: true }],
        password: [{ required: true }]
      },
      loading: false
    }
  },
  created () {
    this.$store.commit('removeEmpInfo')
  },
  methods: {
    // 登录
    login () {
      this.loading = true
      this.api.base.goLogin(this.loginForm).then(result => {
        const data = result.data.data
        Toast.success('登录成功')
        this.utils.getDict(true)
        localStorage.setItem('token', data.access_token)
        localStorage.setItem('refreshToken', data.refresh_token.value)
        localStorage.setItem('tokenType', this._.upperFirst(data.token_type))
        this.$store.commit('updateEmpInfo')
        // 个人信息
        localStorage.setItem('employeeInfo', JSON.stringify(data.userInfo || {}))
        this.$store.commit('updateEmployeeInfo')
        if (this.$route.query.code) {
          // 微信授权
          this.authorize(this.$store.state.employeeInfo)
        }
        const userType = this._.get(this.$store.state, 'employeeInfo.userType', '')
        if ([3, 8].includes(userType)) {
          // 登录后用户校验
          this.usernameCheck(data.userInfo)
        } else {
          // 是否进入首次进入电签
          if (this._.get(this.$store.state, 'employeeInfo.anxinSignAccountStatus', '') === 0 && [3].includes(userType)) {
            this.$router.push({ path: '/signature/grant' })
            return false
          } else {
            if ([4, 5].includes(userType)) {
              this.$router.push({ name: 'message' })
            } else {
              this.$router.push({ name: 'index' })
            }
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 用户校验
    usernameCheck (data) {
      this.api.base.checkMobileLogin({ username: this.loginForm.username }).then(result => {
        const data = result.data.value || {}
        if ([-1, 2].includes(data.authenticationStatus)) {
          this.$router.push({ path: '/personal/information', query: { enterpriseId: data.enterpriseId } })
        } else {
          // 是否进入首次进入电签
          const userType = this._.get(this.$store.state, 'employeeInfo.userType', '')
          if (this._.get(this.$store.state, 'employeeInfo.anxinSignAccountStatus', '') === 0 && [3].includes(userType)) {
            this.$router.push({ path: '/signature/grant' })
            return false
          } else {
            this.utils.getProvinceCityInfo(true)
            this.$router.push({ name: 'index' })
          }
        }
      }).finally(e => {
      })
    },
    // 微信授权
    authorize (val) {
      const { userId, mobile: userPhone } = val
      this.api.base.addSysMsgCollocation({ code: this.$route.query.code, ...{ userId, userPhone } }).then(result => {
      }).finally(e => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .login-page{
    background: #fff;
    background-image: url('../assets/images/bg-logo.png');
    background-size: 360px;
    background-repeat: no-repeat;
  }
  .login-title{
    margin-top: 30%;
  }
  .login-title,
  .login-title-less {
    text-align: left !important;
    margin-left: 6%;
  }
  .border-p{
    width: 8%;
    height: 4px;
    background: #1989fa;
    margin-top: 5% !important;
    margin-left: 6%;
  }
  .div-input{
    margin-top: 10% !important;
    /deep/.van-field__label{
      border-right: 1px solid #aaacaf;
    }
  }
  .van-btn{
    margin-top: 30% !important;
  }

</style>
