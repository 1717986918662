var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-page page" },
    [
      _c("p", { staticClass: "login-title" }, [_vm._v(" 河南农投发展 ")]),
      _c("p", { staticClass: "login-title-less" }, [
        _vm._v(" 供应链管理系统 ")
      ]),
      _c("p", { staticClass: "border-p" }),
      _c(
        "van-form",
        { staticClass: "login-form", on: { submit: _vm.login } },
        [
          _c(
            "div",
            { staticClass: "div-input" },
            [
              _c("van-field", {
                attrs: {
                  placeholder: "请输入用户名",
                  rules: _vm.rules.username,
                  label: "用户名"
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v)
                  },
                  expression: "loginForm.username"
                }
              }),
              _c("van-field", {
                attrs: {
                  placeholder: "请输入密码",
                  rules: _vm.rules.password,
                  label: "密码",
                  type: "password"
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v)
                  },
                  expression: "loginForm.password"
                }
              })
            ],
            1
          ),
          _c(
            "van-button",
            {
              staticClass: "van-btn",
              attrs: {
                block: "",
                type: "info",
                "native-type": "submit",
                disabled: _vm.loading
              }
            },
            [_vm._v(" 登 录 ")]
          )
        ],
        1
      ),
      _c("p", { staticClass: "examine-title" }, [
        _vm._v(" " + _vm._s(_vm.constants.examineTitle) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }